import Papa from 'papaparse'
import consts from '../consts';

export function trimCampos(campos) {
    Object.keys(campos).forEach((key) => {
        if (typeof (campos[key]) === 'string') {
            campos[key] = campos[key].trim()
        } else if ((typeof (campos[key]) === 'object') && (campos[key] !== null)) {
            //Array de campos
            if(campos[key].length){
                campos[key].forEach((obj, index) => {
                    if (!obj instanceof File) { //Verifica se não é arquivo (somente leitura)
                        campos[key][index] = trimCampos(obj)
                    }
                })
            } else {
                //Sub objeto
                Object.keys(campos[key]).forEach((k) => {
                    if (typeof (campos[key][k]) === 'string') {
                        campos[key][k] = campos[key][k].trim()
                    }
                })
            }
        }
    });

    return campos
}

export function montaFormData(campos, nomeCampo, form = new FormData()){
    Object.keys(campos).forEach((key) => {
        //Verifica se não é arquivo
        if (!key.includes('_file')) {
            if (typeof (campos[key]) !== 'object') {
                if (typeof (campos[key]) !== 'boolean') {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), campos[key])
                } else {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), (campos[key] === true ? "1" : "0"))
                }
            } else {
                if (campos[key] !== null) {
                    campos[key].forEach((obj, index) => {
                        form = montaFormData(obj, `${key}[${index}]`, form)
                    })
                }
            }
        } else {
            campos[key].forEach((obj, index) => {
                form.append(`${(!nomeCampo ? key : `${nomeCampo}[${key}]`)}${(campos[key].length > 1 ? '_' + index : '')}`, obj)
            })
        }
    })

    return form
}

export function formatMoeda(valor, somenteNumeros = true) {
    const format = Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(valor)

    return (!somenteNumeros ? format : format.replace('R$ ', '').replace('.', ''))
}

export function formatMoedaPonto(valor) {
    return formatMoeda(valor, true).replace('.', '')
}

export function formatJSPonto(valor, casas = 2){
    if(valor !== undefined && valor != null && valor !== ""){
        if((typeof valor) == "string"){
            valor = valor.replace(',', '.');
        }
        return parseFloat(parseFloat(valor).toFixed(casas));
    } else 
        return parseFloat(0);
}

export function formatJSVirgula(valor, casas = 2){
    if(valor !== undefined && valor !== null && valor !== ""){
        if((typeof valor) === "string"){
            valor = valor.replace(',', '.');
        }
        return parseFloat(valor).toFixed(casas).replace('.', ',');
    } else 
        return parseFloat(0).toFixed(casas).replace('.', ',');
}

export function converteMoedaEmCentavos(valor = '0') {
    const [reais, centavos] = valor.split(',').map(Number);
    return reais * 100 + (centavos ?? 0);
}

export function formataCPF(cpf = ""){
    if(cpf !== "")
        return cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9)
    else
        return cpf
}

export function retiraCaracteres(string = ""){
    return string.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').replaceAll(',', '')
}

export function gerarArquivoCSV(nomeArquivo, dados, colunas = null){
    const csv = Papa.unparse(dados, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ";",
      header: false,
      newline: "\r\n",
      skipEmptyLines: true,
      columns: colunas //or array of strings
    })
    const csvWithBom = "\uFEFF" + csv; // Adiciona o BOM (Byte Order Mark)
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', nomeArquivo)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function downloadArquivo(arquivo){
    const link = document.createElement('a')
    link.href = consts.linkRepositorio + arquivo
    link.setAttribute('download', ''); 
    link.setAttribute('target', '_blank'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}